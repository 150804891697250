import { useState, useEffect } from "react";
import { Loading } from "@components";
import { UserOutlined } from "@ant-design/icons";
import { message, Avatar } from "antd";
import { Imgproxy } from "@utils";
import { Link } from "react-router-dom";
import { Reports } from "@utils";
import * as API from "@api/moderator";
import moment from "moment";

export default function Report({ initialData = null, id = null }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchData = () => {
    setLoading(true);

    API.Reports.getOne({ id }).then(async (res) => {
      if (!res?.data || res?.error) {
        setLoading(false);
        return message.error("Ошибка, попробуйте еще раз", 2);
      }

      if (res?.data?.report?.user_id) {
        res.data.report.user = await API.Users.getOne({
          id: res.data.report.user_id,
        }).then((res) =>
          res?.data
            ? {
                id: res.data.id,
                display_name: res.data.display_name,
                email: res.data.email,
                phone: res.data.phone,
                blocked: res.data.blocked,
                avatar: res.data.avatar,
                customer_type: res.data.customer_type,
              }
            : null
        );
      }

      setData(res.data);
      setLoading(false);
    });
  };

  if (loading) {
    return <Loading compact />;
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      {data?.product && (
        <div className="col-span-12 md:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Объявление
          </span>
          <span className="block text-base font-normal text-slate-800">
            <Link
              target="_blank"
              to={`/listings/view/${data?.product?.type}/${data?.product?.id}`}
            >
              {data?.product?.title}
            </Link>
          </span>
        </div>
      )}
      {data?.company && (
        <div className="col-span-12 md:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Компания
          </span>
          <span className="block text-base font-normal text-slate-800">
            <Link target="_blank" to={`/companies/view/${data?.company?.id}`}>
              {data?.company?.title}
            </Link>
          </span>
        </div>
      )}
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Причина жалобы
        </span>
        <span className="block text-base font-normal text-slate-800">
          {
            Reports.REASON_TITLES[
              data?.report?.product_reason || data?.report?.company_reason
            ]
          }
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Дата жалобы
        </span>
        <span className="block text-base font-normal text-slate-800">
          {moment(data?.report?.created_at).format("DD.MM.YYYY HH:mm")}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Заявитель
        </span>
        <span className="block text-base font-normal text-slate-800">
          {!data?.report?.user && "Аноним"}
          {data?.report?.user && (
            <div className="flex justify-start items-center flex-wrap gap-2">
              <Avatar
                icon={<UserOutlined />}
                src={Imgproxy.getURL(data?.report?.user?.avatar, {
                  width: 100,
                  height: 100,
                })}
              />
              <div>
                <span className="block text-sm font-semibold leading-none text-black m-0 mb-1">
                  {data?.report?.user?.display_name}
                </span>
                <Link
                  className="block text-sm leading-none m-0"
                  target="_blank"
                  to={`/users/view/${data?.report?.user?.id}`}
                >
                  {data?.report?.user?.email}
                </Link>
              </div>
            </div>
          )}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Текст жалобы
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data?.report?.product_comment ||
            data?.report?.company_comment ||
            "Нет"}
        </span>
      </div>
    </div>
  );
}
