import { useEffect, useState } from "react";
import { Table, Tag, Button, message } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import * as CoreAPI from "@api";
import * as API from "@api/moderator";
import { Imgproxy } from "@utils";
import CategoriesFilter from "./filters/categories";
import CountriesFilter from "./filters/countries";
import CitiesFilter from "./filters/cities";
import StatusFilter from "./filters/status";
import SearchFilter from "./filters/search";
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export default function Listings({
  showHeader = true,
  type = "sale",
  showFilters = ["search", "status", "categories", "countries", "cities"],
  defaultFilterValues = null,
  compact = false,
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    categories: [],
    statuses: [
      { id: "in_review", name: "Требует модерации" },
      { id: "published", name: "На сайте" },
      { id: "declined", name: "Отклонен" },
      { id: "unpublished", name: "В архиве" },
      { id: "removed", name: "Удален" },
    ],
    countries: [],
    cities: [],
    users: [],
  });

  const [filter, setFilter] = useState({
    id: pathOr(undefined, ["id"], defaultFilterValues),
    q: pathOr(undefined, ["q"], defaultFilterValues),
    type: pathOr(type, ["type"], defaultFilterValues),
    category_id: pathOr(undefined, ["category_id"], defaultFilterValues),
    status: pathOr(undefined, ["status"], defaultFilterValues),
    country_id: pathOr(undefined, ["country_id"], defaultFilterValues),
    city_id: pathOr(undefined, ["city_id"], defaultFilterValues),
    user_id: pathOr(undefined, ["user_id"], defaultFilterValues),
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getFiltersData();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, [filter, type]);

  // Save filter to storage, for future use
  useEffect(() => {
    localStorage.setItem("listings:filter", JSON.stringify(filter) || null);
  }, [filter]);

  async function getFiltersData() {
    const [countries, cities, categories] = await Promise.all([
      CoreAPI.Countries.getAll({ limit: 100 }),
      CoreAPI.Cities.getAll({ limit: 100 }),
      CoreAPI.Categories.getAll({ limit: 100 }),
    ]);

    setFilters({
      ...filters,
      categories: pathOr([], ["data", "rows"], categories),
      countries: pathOr([], ["data", "rows"], countries),
      cities: pathOr([], ["data", "rows"], cities),
    });
  }

  async function getTableData(params = pagination) {
    const api =
      type === "franchise"
        ? API.Franchises
        : type === "investment"
        ? API.Investments
        : API.Sales;

    setLoading(true);
    const _res = await api.getAll({
      filter: {
        product: filter,
      },
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Error while getting data, please try again later");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const getStatus = (status) => {
    switch (status) {
      case "in_review":
        return (
          <Tag className="m-0" color="orange">
            Требует модерации
          </Tag>
        );
      case "published":
        return (
          <Tag className="m-0" color="green">
            На сайте
          </Tag>
        );
      case "declined":
        return (
          <Tag className="m-0" color="red">
            Отклонено
          </Tag>
        );
      case "unpublished":
        return <Tag className="m-0">Убрано с сайта</Tag>;
      case "removed":
        return (
          <Tag className="m-0" color="red">
            Удалено
          </Tag>
        );

      default:
        break;
    }
  };

  const columns = [
    {
      dataIndex: "image_main",
      key: "image_main",
      align: "center",
      hidden: true,
      width: 150,
      className: "align-top",
      render: (_, data) => (
        <img
          src={
            data
              ? `${Imgproxy.getURL(data.product.image_main, {
                  width: 180,
                  height: 180,
                  resize: "fit",
                  background: "fff",
                })}`
              : "/img/no-image.svg"
          }
          className="block w-full"
          style={{ maxWidth: 150 }}
          alt="Изображение объявления"
        />
      ),
    },
    {
      title: "Объявление",
      dataIndex: "product",
      key: "product",
      align: "left",
      width: 600,
      render: (data, all) => (
        <div>
          {getStatus(all.product.status)}
          <Link
            to={`/listings/view/${all.product.type}/${all.product.id}`}
            className="block m-0 mt-2 text-base text-black"
          >
            {data.title}
          </Link>
          <span className="block mb-2 font-normal text-black text-sm">
            {new Intl.NumberFormat("ru-KZ", {
              style: "currency",
              currency: "kzt",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(data.price)}{" "}
            &middot;{" "}
            <Link to={`/users/view/${data.user_id}`}>{data.display_name}</Link>
          </span>
          <span className="block font-normal text-slate-500 text-xs">
            {moment(data.updated_at).fromNow()} (
            {moment(data.updated_at).format("DD MMM YYYY в HH:mm")}) &middot;{" "}
            {all?.category?.name} &middot; {all?.city?.name}
          </span>
        </div>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, all) => (
        <Link
          target="_blank"
          to={`/listings/view/${all.product.type}/${all.product.id}`}
        >
          <Button icon={<EyeOutlined />}>Открыть</Button>
        </Link>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-6">
      {includes("search", showFilters) && [
        <div className="col-span-12 lg:col-span-6">
          <SearchFilter
            defaultValue={defaultFilterValues?.q}
            onSearch={onFilterChange}
          />
        </div>,
        <div className="col-span-12 lg:col-span-6">
          <SearchFilter
            defaultValue={defaultFilterValues?.id}
            byId
            onSearch={onFilterChange}
          />
        </div>,
      ]}
      {includes("status", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <StatusFilter
            defaultValue={defaultFilterValues?.status}
            data={filters.statuses}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("categories", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <CategoriesFilter
            defaultValue={defaultFilterValues?.category_id}
            data={filters.categories}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("countries", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <CountriesFilter
            defaultValue={defaultFilterValues?.country_id}
            data={filters.countries}
            onChange={onFilterChange}
          />
        </div>
      )}
      {includes("cities", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <CitiesFilter
            defaultValue={defaultFilterValues?.city_id}
            data={filters.cities}
            onChange={onFilterChange}
          />
        </div>
      )}
      <div className="col-span-12">
        <Table
          key={type}
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={
            compact
              ? columns.filter(
                  (col) => col.key !== "image_main" && col.key !== "actions",
                  columns
                )
              : columns
          }
        />
      </div>
    </div>
  );
}
