import { useState } from "react";
import { Button, message, Form, Divider, Segmented } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import * as API from "@api/moderator";
import Products from "./options/products";
import Packages from "./options/packages";
import Tariffs from "./options/tariffs";
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export default function NewPayment({ className }) {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("package");

  const onSubmit = (values) => {
    setLoading(true);
    API.Payments.create({ ...values, type }).then((res) => {
      if (!res || res?.error) {
        setLoading(false);
        return message.error("Невозможно создать платеж", 3);
      }

      return message.success("Платеж успешно создан", 3, () => {
        window.location.reload(false);
      });
    });
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12">
        <Form
          layout="vertical"
          scrollToFirstError
          onFinish={onSubmit}
          onErrorCapture={console.log}
          onFinishFailed={console.log}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Выберите объявление",
              },
            ]}
            name="product_id"
            required
            label="Объявление"
          >
            <Products loading={loading} />
          </Form.Item>

          <div className="bg-neutral-50 rounded-xl p-5">
            <Segmented
              className="mb-5"
              defaultValue={type}
              block
              onChange={setType}
              options={[
                { label: "Пакеты услуг", value: "package" },
                { label: "Отдельные услуги", value: "tariff" },
              ]}
            />
            {type === "package" && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Выберите пакет услуг",
                  },
                ]}
                className="m-0"
                name="package_id"
                required
              >
                <Packages loading={loading} />
              </Form.Item>
            )}
            {type === "tariff" && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Выберите услугу",
                  },
                ]}
                className="m-0"
                name="tariff_id"
                required
              >
                <Tariffs loading={loading} />
              </Form.Item>
            )}
          </div>

          <Form.Item className="mb-0">
            <Divider />
            <div className="flex justify-between items-center">
              <Button
                htmlType="submit"
                icon={<CreditCardOutlined />}
                loading={loading}
                type="primary"
              >
                Создать платеж
              </Button>
              <span className="text-xs text-orange-500 ml-5 leading-tight">
                Услуга активируется сразу, убедитесь, что клиент произвел
                оплату!
              </span>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
