import { useEffect, useState } from "react";
import { message, Table, Button, Modal, Badge } from "antd";
import { useAuth } from "@contexts";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";

import { useLocale } from "@contexts/locale";
import * as CoreAPI from "@api";
import * as API from "@api/customer";

import "moment/locale/ru";
import "moment/locale/kk";

moment.locale("ru");

export default function Notifications({ className, initialData }) {
  const { t } = useTranslation();
  const { locale } = useLocale();

  if (locale === "kk") {
    moment.locale("kk");
  } else if (locale === "en") {
    moment.locale("en");
  }

  const { currentUser, setCurrentUser } = useAuth();
  const [loading, setLoading] = useState(!initialData);
  const [data, setData] = useState(initialData?.data || []);
  const [pagination, setPagination] = useState(
    initialData?.pagination || {
      current: 1,
      pageSize: 10,
      total: 1,
      position: ["bottomright"],
      showSizeChanger: true
    }
  );

  useEffect(() => {
    if (!initialData) {
      getTableData();
    }
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    CoreAPI.Auth.getAuth().then(_resUser => {
      if (_resUser?.data?.notifications !== currentUser.notifications) {
        setCurrentUser({
          ...currentUser,
          notifications: _resUser.data.notifications
        });
      }
    });
    /* eslint-disable-next-line */
  }, [data]);

  // Get all notifications
  async function getTableData(params = pagination) {
    setLoading(true);
    const [_resNotifications] = await Promise.all([
      API.Notifications.getAll({
        limit: params.pageSize,
        offset: params.current * params.pageSize - params.pageSize
      })
    ]);

    if (!_resNotifications.data) {
      return message.error(t("generic.serverError"), 2);
    }

    setData(_resNotifications.data.rows);
    setPagination({ ...params, total: _resNotifications.data.count });
    setLoading(false);
  }

  async function onRead(notification) {
    await API.Notifications.read({
      id_list: [notification.id]
    }).then(() => getTableData());

    Modal.info({
      width: 500,
      okText: t("generic.done"),
      title: notification.title,
      maskClosable: true,
      content: (
        <div>
          <span className="block font-normal text-black text-base">{notification.description}</span>
          <span className="block font-normal text-slate-500 text-sm mt-5">
            {t("notifications.received")}: {moment(notification.created_at).format("HH:mm DD.MM.YYYY")}{" "}
          </span>
          <span className="block font-normal text-slate-500 text-sm mt-1">{getType(notification.type)}</span>
        </div>
      )
    });
  }

  function getType(type) {
    switch (type) {
      case "product_status_change":
        return "Статус объявления изменен";

      default:
        return "Другое";
    }
  }

  return (
    <div className={classNames("block w-full", className)}>
      <Table
        showHeader={false}
        onChange={getTableData}
        pagination={pagination}
        dataSource={data}
        loading={loading}
        className="rounded-sm overflow-hidden"
        rowKey="id"
        columns={[
          {
            dataIndex: "status",
            key: "status",
            align: "center",
            width: 30,
            render: data => (data === "unread" ? <Badge className="block m-0" status="error" /> : <Badge className="block m-0" status="default" />)
          },
          {
            dataIndex: "title",
            key: "title",
            align: "left",
            render: (data, all) => (
              <div className="cursor-pointer" onClick={() => onRead(all)} icon={<EyeOutlined />}>
                <h4 className="block m-0 text-base">{data}</h4>
              </div>
            )
          },
          {
            dataIndex: "type",
            key: "type",
            align: "left",
            responsive: ["md"],
            render: data => <span className="block m-0 font-normal text-slate-500 text-sm">{getType(data)}</span>
          },
          {
            dataIndex: "created_at",
            key: "created_at",
            align: "center",
            responsive: ["lg"],
            render: data => <span className="block m-0 font-normal text-slate-500 text-sm">{moment(data).fromNow()}</span>
          },
          {
            dataIndex: "actions",
            key: "actions",
            align: "right",
            responsive: ["lg"],
            render: (_, all) => (
              <Button onClick={() => onRead(all)} icon={<EyeOutlined />}>
                {t("generic.open")}
              </Button>
            )
          }
        ]}
      />
    </div>
  );
}
