import { useEffect, useState } from "react";
import { message, Select } from "antd";
import moment from "moment";
import * as API from "@api/moderator";

export default function Tariffs({ loading, ...props }) {
  const [fetching, setFetching] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () =>
    API.Tariffs.getAll({ limit: 50 }).then((res) => {
      if (!res || res?.error) {
        setFetching(false);
        return message.error("Невозможно загрузить данные", 3);
      }

      setOptions(
        res?.data?.rows?.map((tariff) => ({
          value: tariff?.id,
          label: (
            <span>
              <i className="not-italic font-medium">
                {tariff?.name} (
                {moment.duration(tariff?.quantity, tariff?.unit).humanize()}):
              </i>{" "}
              <i className="not-italic text-neutral-500">
                {new Intl.NumberFormat(
                  "ru-KZ",
                  {
                    style: "currency",
                    currency: "KZT",
                  },
                  { maximumFractionDigits: 0, minimumFractionDigits: 0 }
                ).format(parseFloat(tariff?.prices[0]?.price))}
              </i>
            </span>
          ),
        }))
      );
      setFetching(false);
    });

  return (
    <Select
      allowClear
      className="w-full"
      placeholder="Выберите услугу"
      filterOption={false}
      options={options}
      loading={loading || fetching}
      disabled={loading || fetching}
      {...props}
    />
  );
}
