import { useState, useEffect } from "react";
import { message, Tag, Card, Button, List, Popconfirm, Avatar, Pagination, Divider, Badge } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import {
  UpOutlined,
  StopOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Loading } from "@components";
import { Imgproxy } from "@utils";
import * as API from "@api/moderator";
import moment from "moment";
import {find, propEq} from "ramda";
import {statuses} from "../../listings/info/head/dict";
const limit = 10
const getFormattedPrice = (price) => Intl.NumberFormat("ru-KZ", {
  currency: "kzt",
  style: "currency",
  maximumFractionDigits: 0,
}).format(price)
export default function User() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState(null);
  const [currentPageProductContacts, setPageProductContacts] = useState(1);
  const [dataOutgoingCalls, setDataOutgoingCalls] = useState(null);
  const [loadingProductContacts, setLoadingProductContacts] = useState(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
      return;
    }

    fetchData();
    /* eslint-disable-next-line */
  }, [data]);

  const fetchData = () =>
    API.Users.getOne({ id }).then((res) => {
      if (!res?.data || res?.error) {
        return message.error(
          "Ошибка во время загрузки объявления. Попробуйте позже.",
          3
        );
      }

      setData(res.data);
      setLoading(false);
      fetchProductContactsByUser();
    });
  const fetchProductContactsByUser = (offs = 0) => {
    return API.Analytics.getProductContactsByUser({ userId: id, params: { limit: limit, offset: offs }}).then((res) => {
      if (!res?.data || res?.error) {
        return message.error(
            "Ошибка во время загрузки объявления. Попробуйте позже.",
            3
        );
      }

      setDataOutgoingCalls(res.data);
      setLoadingProductContacts(false)
    });
  }

  if (loading) {
    return <Loading />;
  }

  const onBlock = async () => {
    setSaving(true);
    const _res = await API.Users.block({ id });
    setSaving(false);

    if (!_res || _res.error) {
      return message.error(
        "Ошибка во время блокировки пользователя. Попробуйте позже.",
        2
      );
    }

    message.success("Пользователь заблокирован", 2, () => navigate(0));
  };

  const onContactsConfirm = async ({ id }) => {
    setSaving(true);
    const _res = await API.Users.confirmContacts({ id });
    setSaving(false);

    if (!_res || _res.error) {
      return message.error(
        "Ошибка во время подтверждения номера. Попробуйте позже.",
        2
      );
    }

    message.success("Подвязанный номер подтвержден", 1, () => navigate(0));
  };

  const onUnblock = async () => {
    setSaving(true);
    const _res = await API.Users.unblock({ id });
    setSaving(false);

    if (!_res || _res.error) {
      return message.error(
        "Ошибка во время разблокировки пользователя. Попробуйте позже.",
        2
      );
    }

    message.success("Пользователь разблокирован", 2, () => navigate(0));
  };

  const getPriceString = (product) => {
    return product?.price_max
        ? `${getFormattedPrice(product?.price)} - ${getFormattedPrice(product?.price_max)}`
        : getFormattedPrice(product?.price);
  }
  const getStatusText = (status) => {
    return find(propEq("value", status))(statuses) || {};
  }
  const updatePageProductContacts = (page) => {
    setPageProductContacts(page)
    setLoadingProductContacts(true)
    fetchProductContactsByUser(limit * (page - 1))
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="col-span-12 lg:col-span-3">
        <Card bordered={false} title="Основная информация" className="h-full">
          <Avatar
            src={Imgproxy.getURL(data?.avatar, { width: 100, height: 100 })}
            icon={<UserOutlined />}
            size={70}
            className="block mx-auto mb-5"
          />
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Имя
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              {data.display_name}
            </p>
          </section>
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Эл.почта
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              {data.email}
            </p>
          </section>
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Основной номер
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              {data.phone || "Не указан"}
            </p>
          </section>
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0 mb-5">
              Действия
            </span>
            {data.blocked ? (
              <Button
                onClick={onUnblock}
                loading={saving}
                type="primary"
                icon={<UpOutlined />}
              >
                Разблокировать
              </Button>
            ) : (
              <Button
                onClick={onBlock}
                loading={saving}
                danger
                type="primary"
                icon={<StopOutlined />}
              >
                Заблокировать
              </Button>
            )}
          </section>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Card
          bordered={false}
          title="Дополнительная информация"
          className="h-full"
        >
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Статус
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              {data.blocked ? (
                <Tag className="m-0" color="red">
                  Заблокирован
                </Tag>
              ) : (
                <Tag className="m-0" color="green">
                  Активен
                </Tag>
              )}
            </p>
          </section>
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Дата регистрации
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              {moment(data.created_at).format("HH:mm DD.MM.YYYY")}
            </p>
          </section>
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Посл. вход
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              {moment(data.last_login_at).format("HH:mm DD.MM.YYYY")}
            </p>
          </section>
          <section className="block mb-5">
            <span className="text-sm font-semibold text-slate-700 block m-0">
              Уведомления
            </span>
            <p className="text-base text-slate-600 m-0 mt-1 whitespace-pre-wrap">
              Подписан
            </p>
          </section>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Card bordered={false} title="Исходящие звонки" className="h-full" bodyStyle={{minHeight: 'calc(100% - 55px)', display: 'flex', flexDirection: 'column', overflowX: "hidden"}}>
          <div className="overflow-x-hidden">
            <div className="max-h-[350px] overflow-x-hidden overflow-y-auto pb-8 pr-4 -mr-4">
              { loadingProductContacts && <Loading compact />}
              {
                  !loadingProductContacts && dataOutgoingCalls && dataOutgoingCalls.rows.map((statistic, idx) => (
                  <div key={'outgoing-calls-' + idx}>
                    { idx !== 0 && <Divider style={{ margin: "15px 0"}} /> }
                    <List>
                      <a href={`/listings/view/${statistic.product?.type}/${statistic.product?.id}`} target="_blank" className="text-base font-semibold text-slate-700 block m-0" rel="noreferrer">
                        {statistic.product?.title}
                      </a>
                        <span className="text-sm font-semibold text-slate-700 block m-0 mb-2">
                          {getPriceString(statistic?.product)}&nbsp;&nbsp;
                          <Badge
                              {...getStatusText(statistic?.product?.status).tagProps}
                              text={getStatusText(statistic?.product?.status).label}
                              style={{
                                color: getStatusText(statistic?.product?.status).tagProps.color
                              }}
                          />
                        </span>
                        <span className="block font-normal text-slate-500 text-xs mb-1">
                          {statistic.product?.category?.name}
                          &nbsp;·&nbsp;
                          {statistic.product?.city?.name}
                        </span>
                        <span className="block font-normal text-slate-700 text-xs">
                          {moment(statistic.date).startOf('minutes').fromNow()}&nbsp;
                          ({moment(statistic.date).format("DD MMM YYYY, в HH:mm")})
                        </span>
                    </List>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="outgoing-calls__actions flex mt-auto items-center flex-wrap justify-between">
            <div className="mr-2">Найдено: { dataOutgoingCalls?.count || 0 }</div>
            <Pagination className={{ hidden: !dataOutgoingCalls?.count }} defaultCurrent={1} current={currentPageProductContacts} onChange={updatePageProductContacts} total={dataOutgoingCalls?.count || 0} size="small" simple />
          </div>
        </Card>
      </div>
      <div className="col-span-12 lg:col-span-3">
        <Card bordered={false} title="Подвязанные номера" className="h-full">
          {data?.contacts?.length === 0 && "Нет подвязанных номеров"}
          <List>
            {data.contacts.map((phone) => (
              <List.Item className="text-sm text-black" key={'contact-' + phone.phone}>
                <PhoneOutlined className="mr-1" /> {phone.phone}{" "}
                <span className="text-slate-500">
                  {phone.phone_confirmed ? (
                    "(Подтвержден)"
                  ) : (
                    <Popconfirm
                      okText="Подтвердить"
                      title="Подтвердить этот номер?"
                      onConfirm={() => onContactsConfirm({ id: phone.id })}
                    >
                      <Button type="link" className="m-0 p-0 h-fit min-h-0">
                        Подтвердить
                      </Button>
                    </Popconfirm>
                  )}
                </span>
              </List.Item>
            ))}
          </List>
        </Card>
      </div>
    </div>
  );
}
