import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";
import CacheBuster from "react-cache-buster";
import AuthContextProvider from "@contexts";
import LocaleProvider from "@contexts/locale";
import config from "@config";
import ruRU from "antd/lib/locale/ru_RU";
import kkKZ from "antd/lib/locale/kk_KZ";
import enUS from 'antd/lib/locale/en_US';
import pkg from "../package.json";
import { Loading } from "@components";
import { BrowserTracing } from "@sentry/tracing";
import { ConfigProvider } from "antd";
import { useLocale } from "@contexts/locale";
import "antd/dist/reset.css";
import "@styles/global.scss";

if (config.env === "production") {
  Sentry.init({
    dsn: "https://e991a138115f4221a2e7d21d75e1dedd@o1343877.ingest.sentry.io/6619266",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    release: `sdelka-cabinet@${pkg.version}`,
    environment: config.env,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <CacheBuster
    currentVersion={pkg.version}
    isEnabled
    isVerboseMode={false}
    loadingComponent={<Loading />}
  >
    <LocaleProvider>
      <WithLocale />
    </LocaleProvider>
  </CacheBuster>
);

function WithLocale() {
  const { locale } = useLocale();

  return (
    <ConfigProvider locale={locale === "kk" ? kkKZ : locale === "en" ? enUS : ruRU} {...config.antd}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ConfigProvider>
  );
}
