import { Card, Button, Avatar, Badge, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "@api/moderator";
import { Imgproxy } from "@utils";
import moment from "moment";
import config from "@config";

export default function Head({ data }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onActivate = () => {
    setLoading(true);
    API.Companies.activateCompany(data?.company?.id).then(async (res) => {
      if (!res || res.error) {
        setLoading(false);
        return message.error(
          "Произошла ошибка. Попробуйте снова или обратитесь к администратору.",
          3
        );
      }

      return message.success("Компания на сайте.", 2, () => navigate(0));
    });
  };

  const onDeactivate = () => {
    setLoading(true);
    API.Companies.deactivateCompany(data?.company?.id).then(async (res) => {
      if (!res || res.error) {
        setLoading(false);
        return message.error(
          "Произошла ошибка. Попробуйте снова или обратитесь к администратору.",
          3
        );
      }

      return message.success("Компания убрана с сайта.", 2, () => navigate(0));
    });
  };

  return (
    <Card bordered={false} className="p-0 sticky">
      <div className="grid grid-cols-12 gap-y-10 gap-x-4 lg:gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-2">
          <img
            className="block mx-auto aspect-square w-32 h-32 object-cover object-center rounded-sm bg-neutral-50"
            src={Imgproxy.getURL(data?.company?.logo, {
              width: 120,
              height: 120,
              resize: "fit",
            })}
            alt="Логотип"
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-lg font-semibold leading-tight mb-1">
            {data?.company?.title}
          </span>
          {data?.company?.subtitle && (
            <span className="block text-base leading-tight mb-1">
              {data?.company?.subtitle}
            </span>
          )}
          <span className="block text-sm font-normal text-neutral-500 mb-1">
            {data?.category?.name}
          </span>
          <div className="flex flex-wrap items-center mt-5 gap-2">
            <Button
              hidden={data?.company?.is_active}
              onClick={onActivate}
              loading={loading}
              className="w-full lg:w-fit"
              icon={<CheckOutlined />}
              type="primary"
            >
              Опубликовать
            </Button>
            <Button
              hidden={!data?.company?.is_active}
              onClick={onDeactivate}
              loading={loading}
              className="w-full lg:w-fit"
              type="primary"
              danger
            >
              Снять с сайта
            </Button>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="flex items-center gap-2">
            <Avatar
              size="large"
              src={Imgproxy.getURL(data?.user?.avatar, {
                width: 100,
                height: 100,
              })}
            />
            <div>
              <Link
                to={`/users/view/${data?.user?.id}`}
                className="block leading-tight font-semibold text-base text-black"
              >
                {data?.user?.display_name}
              </Link>
              <span className="block leading-tight font-normal text-sm text-neutral-500">
                {data?.user?.email}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-5">
            <span className="col-span-2 md:col-span-1 text-sm">
              <i className="font-semibold not-italic">Создано:</i>{" "}
              {moment(data?.company?.created_at).format("HH:mm DD.MM.YYYY")}
            </span>
            <span className="col-span-2 md:col-span-1 text-sm">
              <i className="font-semibold not-italic">Обновлено:</i>{" "}
              {moment(data?.company?.updated_at).format("HH:mm DD.MM.YYYY")}
            </span>
            <span className="col-span-2 md:col-span-1 text-sm">
              <i className="font-semibold not-italic">Ссылка:</i>{" "}
              <Button
                disabled={!data?.company?.is_active}
                type="link"
                className="h-fit m-0 p-0 bg-transparent border-none"
                target="_blank"
                href={`${config.frontend}/services/s/${data?.company?.id}/${data?.company?.slug}`}
              >
                Открыть на сайте
              </Button>
            </span>
            <span className="col-span-2 md:col-span-1 text-sm">
              <Badge
                status={data?.company?.is_active ? "success" : "error"}
                text={data?.company?.is_active ? "На сайте" : "Снято с сайта"}
              />
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}
