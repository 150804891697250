import { List, Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Users } from "@api/moderator";
import { Imgproxy } from "@utils";
import moment from "moment";

export default function UserList({ initialData = [], params = {} }) {
  /* eslint-disable-next-line */
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (data?.length === 0 || !data) {
      fetchUsers();
    }
    /* eslint-disable-next-line */
  }, [initialData]);

  const fetchUsers = () => {
    Users.getAll(params).then((res) => {
      setData(res.data.rows);
      setLoading(false);
    });
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      className="w-full m-0"
      renderItem={(item, index) => (
        <List.Item key={index} className="px-0">
          <List.Item.Meta
            avatar={
              <Avatar
                size="large"
                icon={<UserOutlined />}
                src={Imgproxy.getURL(item?.avatar, { width: 100, height: 100 })}
              />
            }
            title={
              <Link className="m-0 leading-none" to={`/users/view/${item.id}`}>
                {item.display_name}
              </Link>
            }
            description={
              <div>
                <Tooltip title="Эл. почта">
                  <span>{item.email}</span> &middot;{" "}
                </Tooltip>
                <Tooltip title="Посл. вход">
                  <span>{moment(item.last_login_at).fromNow()}</span>
                </Tooltip>{" "}
                &middot;{" "}
                <Tooltip title="Дата регистрации">
                  <span>
                    {moment(item.created_at).format("HH:mm DD.MM.YYYY")}
                  </span>
                </Tooltip>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
}
