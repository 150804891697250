import { Card, Button, Avatar, Badge, Checkbox, Tooltip, message } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { statuses, types } from "./dict";
import { find, propEq } from "ramda";
import { Link } from "react-router-dom";
import { useState } from "react";
import { revalidateClientData } from "@api";
import { useNavigate } from "react-router-dom";
import * as API from "@api/moderator";
import { Imgproxy } from "@utils";
import moment from "moment";
import config from "@config";

export default function Head({ data }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(!!data?.sale?.is_verified);
  const status = find(propEq("value", data.product.status))(statuses);
  const type = find(propEq("value", data.product.type))(types);
  const price = data?.product?.price_max
    ? `${Intl.NumberFormat("ru-KZ", {
        currency: "kzt",
        style: "currency",
        maximumFractionDigits: 0,
      }).format(data?.product?.price)} - ${Intl.NumberFormat("ru-KZ", {
        currency: "kzt",
        style: "currency",
        maximumFractionDigits: 0,
      }).format(data?.product?.price_max)}`
    : Intl.NumberFormat("ru-KZ", {
        currency: "kzt",
        style: "currency",
        maximumFractionDigits: 0,
      }).format(data?.product?.price);

  const api =
    type?.value === "franchise"
      ? API.Franchises
      : type?.value === "investment"
      ? API.Investments
      : API.Sales;

  const previewProductType =
    data?.product?.type === "investment" ? "startups" : data?.product?.type;
  const previewLink =
    data?.product?.status !== "published"
      ? `${config.frontend}/preview/${previewProductType}/${data?.product?.id}`
      : `${config.frontend}/${previewProductType}/s/${data?.product?.id}/${data?.product?.slug}`;

  const onApprove = () => {
    setLoading(true);
    const payload = { id: data?.product?.id };

    // Verified badge for Sales
    if (data?.product?.type === "sale") {
      payload.is_verified = verified;
    }

    api.publish(payload).then(async (res) => {
      if (!res || res.error) {
        setLoading(false);
        return message.error(
          "Произошла ошибка. Попробуйте снова или обратитесь к администратору.",
          3
        );
      }

      const updated = await api.getOne({ id: data?.product?.id });

      await revalidateClientData({
        id: updated?.data?.product?.id,
        slug: updated?.data?.product?.slug,
        category: updated?.data?.product?.type,
      });

      return message.success("Объявление опубликовано.", 2, () => navigate(0));
    });
  };

  const onReject = () => {
    setLoading(true);
    api.decline({ id: data?.product?.id }).then(async (res) => {
      if (!res || res.error) {
        setLoading(false);
        return message.error(
          "Произошла ошибка. Попробуйте снова или обратитесь к администратору.",
          3
        );
      }

      const updated = await api.getOne({ id: data?.product?.id });

      await revalidateClientData({
        id: updated?.data?.product?.id,
        slug: updated?.data?.product?.slug,
        category: updated?.data?.product?.type,
      });

      return message.success(
        "Объявление отклонено и отправлено обратно к пользователю.",
        2,
        () => navigate(0)
      );
    });
  };

  const onUnpublish = () => {
    setLoading(true);
    api.unpublish({ id: data?.product?.id }).then(async (res) => {
      if (!res || res.error) {
        setLoading(false);
        return message.error(
          "Произошла ошибка. Попробуйте снова или обратитесь к администратору.",
          3
        );
      }

      const updated = await api.getOne({ id: data?.product?.id });

      await revalidateClientData({
        id: updated?.data?.product?.id,
        slug: updated?.data?.product?.slug,
        category: updated?.data?.product?.type,
      });

      return message.success("Объявление снято с сайта.", 2, () => navigate(0));
    });
  };

  return (
    <Card bordered={false} className="p-0 sticky">
      <div className="grid grid-cols-12 gap-y-10 gap-x-4 lg:gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-2">
          {data?.product?.type === "franchise" ||
          data?.product?.type === "investment" ? (
            <img
              className="block mx-auto aspect-square w-32 h-32 object-cover object-center rounded-sm bg-neutral-50"
              src={Imgproxy.getURL(data[type.value]?.logo, {
                width: 120,
                height: 120,
                resize: "fit",
              })}
              alt="Фото объявления"
            />
          ) : (
            <img
              className="block w-full max-h-32 object-contain object-center rounded-sm bg-neutral-50"
              src={Imgproxy.getURL(data?.product?.image_main, {
                width: 205,
                height: 128,
                resize: "fit",
              })}
              alt="Фото объявления"
            />
          )}
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-lg font-semibold leading-tight mb-1">
            {data.product.title}
          </span>
          <span className="block text-sm font-normal text-neutral-500 mb-1">
            <i className="text-green-600 not-italic">{type.label}</i> &middot;{" "}
            {data.category.name} &middot;{" "}
            {`${data?.city?.name}, ${data?.country?.name}`}
          </span>
          <span className="block text-sm font-medium text-green-600">
            {price}
          </span>
          {data?.product?.type === "sale" && (
            <div className="px-3 py-1 bg-green-100 block w-full lg:w-fit rounded-md mt-3 mb-0">
              <Checkbox
                disabled={data?.product?.status !== "in_review"}
                checked={verified}
                onChange={(e) => setVerified(e.target.checked)}
              >
                <Tooltip
                  placement="right"
                  title="Выберите, если объявление прошло процесс верификации."
                >
                  Объявление верифицировано
                </Tooltip>
              </Checkbox>
            </div>
          )}
          <div className="flex flex-wrap items-center gap-2 mt-3">
            <Button
              hidden={data?.product?.status !== "in_review"}
              onClick={onApprove}
              loading={loading}
              className="w-full lg:w-fit"
              icon={<CheckOutlined />}
              type="primary"
            >
              Опубликовать
            </Button>
            <Button
              loading={loading}
              className="w-full lg:w-fit"
              type={data?.product?.status === "in_review" ? "text" : "default"}
              href={`/listings/edit/${data?.product?.type}/${data?.product?.id}`}
            >
              Редактировать
            </Button>
            <Button
              hidden={data.product.status !== "in_review"}
              onClick={onReject}
              loading={loading}
              className="w-full lg:w-fit"
              type="text"
              danger
            >
              Отклонить
            </Button>
            <Button
              hidden={data.product.status !== "published"}
              onClick={onUnpublish}
              loading={loading}
              className="w-full lg:w-fit"
              type="text"
              danger
            >
              Снять с сайта
            </Button>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-4">
          <div className="flex items-center gap-2">
            <Avatar
              size="large"
              src={Imgproxy.getURL(data?.product?.user?.avatar, {
                width: 100,
                height: 100,
              })}
            />
            <div>
              <Link
                to={`/users/view/${data?.product?.user?.id}`}
                className="block leading-tight font-semibold text-base text-black"
              >
                {data?.product?.user?.display_name}
              </Link>
              <span className="block leading-tight font-normal text-sm text-neutral-500">
                {data?.product?.user?.email}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-5">
            <span className="col-span-2 md:col-span-1 text-sm">
              <i className="font-semibold not-italic">Создано:</i>{" "}
              {moment(data?.product?.created_at).format("HH:mm DD.MM.YYYY")}
            </span>
            <span className="col-span-2 md:col-span-1 text-sm">
              <i className="font-semibold not-italic">Обновлено:</i>{" "}
              {moment(data?.product?.updated_at).format("HH:mm DD.MM.YYYY")}
            </span>
            <span className="col-span-2 md:col-span-1 text-sm">
              <i className="font-semibold not-italic">Ссылка:</i>{" "}
              <Button
                type="link"
                className="h-fit m-0 p-0 bg-transparent border-none"
                target="_blank"
                href={previewLink}
              >
                {data?.product?.status !== "published"
                  ? "Предпросмотр"
                  : "Открыть на сайте"}
              </Button>
            </span>
            <span className="col-span-2 md:col-span-1 text-sm">
              <Badge {...status.tagProps} text={status.label} />
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}
