import { Fragment, useState } from "react";
import { Feedbacks } from "@modules";
import { Button, Tooltip, Modal, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { revalidateClientData } from "@api";
import { includes } from "ramda";
import { Link } from "react-router-dom";
import { Imgproxy } from "@utils";
import { useTranslation } from "react-i18next";
import Promotions from "./promotions";
import classNames from "classnames";
import * as API from "@api/customer";

export default function Details({ data, isApp }) {
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const { t } = useTranslation();

  const api =
    data?.product?.type === "franchise"
      ? API.Franchises
      : data?.product?.type === "investment"
      ? API.Investments
      : API.Sales;

  const onUnpublish = async ({ id, category, slug }) => {
    Modal.confirm({
      title: t("listings.unpublishPromptTitle"),
      content: t("listings.ubpublishPromptText"),
      okText: t("generic.yes"),
      cancelText: t("generic.no"),
      onOk: async () => {
        const res = await api.unpublish({ id });
        if (!res || res.error) {
          return message.error("Произошла ошибка, попробуйте еще раз.", 2);
        }
        await revalidateClientData({ id, category, slug });

        setFeedbackVisible(true);
      },
    });
  };

  const EditButton = () => (
    <Link
      className="w-full"
      to={`/listings/${data.product.type}/edit/${data.product.id}`}
    >
      <Button type="primary" block icon={<EditOutlined />}>
        {t("generic.edit")}
      </Button>
    </Link>
  );

  const EditButtonApp = () => (
    <Link
      className="block w-full"
      to={`/app/listings/${data.product.type}/edit/${data.product.id}`}
    >
      <Button size="large" type="primary" block icon={<EditOutlined />}>
        {t("generic.edit")}
      </Button>
    </Link>
  );

  const UnpublishButton = () => (
    <Button
      danger
      type="text"
      className="mt-2"
      block
      icon={<CloseCircleOutlined />}
      onClick={() =>
        onUnpublish({
          id: data?.product?.id,
          category: data?.product?.type,
          slug: data?.product?.slug,
        })
      }
      hidden={!includes(data.product.status, ["published"])}
    >
      {t("listings.unpublish")}
    </Button>
  );

  const UnpublishButtonApp = () => (
    <Button
      danger
      block
      size="large"
      type="default"
      className="mt-3"
      icon={<DeleteOutlined />}
      onClick={() =>
        onUnpublish({
          id: data?.product?.id,
          category: data?.product?.type,
          slug: data?.product?.slug,
        })
      }
      hidden={!includes(data.product.status, ["published"])}
    >
      {t("listings.unpublish")}
    </Button>
  );

  return (
    <Fragment>
      <img
        className="block w-full h-56 lg:h-40 rounded-sm shadow-md object-cover object-center"
        src={Imgproxy.getURL(data.product.image_main, {
          width: 500,
          height: 340,
          resize: "fit",
          background: "fff",
        })}
        alt="Ваше объявление"
      />
      {data.product.type === "sale" && (
        <span className="text-xs text-neutral-400 block my-3">
          {t("generic.businessSale")}
        </span>
      )}
      {data.product.type === "franchise" && (
        <span className="text-xs text-neutral-400 block my-3">
          {t("generic.franchiseSale")}
        </span>
      )}
      {data.product.type === "investment" && (
        <span className="text-xs text-neutral-400 block my-3">
          {t("generic.startupSale")}
        </span>
      )}
      <span className="block m-0 leading-snug text-base font-semibold break-all">
        {data.product.title}
      </span>
      <div
        className={classNames("flex flex-col justify-start items-start mt-2")}
      >
        {!includes(data.product.status, ["in_review"]) &&
          (isApp ? <EditButtonApp /> : <EditButton />)}
        {isApp && <Promotions isApp={isApp} data={data} />}
        <Tooltip title={t("listings.unpublish")}>
          {isApp ? <UnpublishButtonApp /> : <UnpublishButton />}
        </Tooltip>
        {feedbackVisible && (
          <Feedbacks.Product
            data={data}
            visible={feedbackVisible}
            onCancel={() => setFeedbackVisible(false)}
          />
        )}
      </div>
    </Fragment>
  );
}
