import { useEffect, useState } from "react";
import { Table, Button, message, Modal } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { pathOr, includes, find, propEq } from "ramda";
import { services } from "./filters/services";
import ServicesFilter from "./filters/services";
import ProductsFilter from "./filters/products";
import UsersFilter from "./filters/users";
import View from "../view";
import { Imgproxy, Products } from "@utils";
import * as API from "@api/moderator";
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export default function Promotions({
  showHeader = true,
  showFilters = ["services", "products", "users", "dates"],
  defaultFilterValues = null,
  compact = false,
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [promotionId, setPromotionId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    service_code: pathOr(undefined, ["service_code"], defaultFilterValues),
    product_id: pathOr(undefined, ["product_id"], defaultFilterValues),
    user_id: pathOr(undefined, ["user_id"], defaultFilterValues),
    status: pathOr(undefined, ["status"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, [filter]);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.ExternalPromotions.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Ошибка, попробуйте еще раз");
    }

    const products = await API.Products.getAll({
      filter: {
        id: _res?.data?.rows?.map((row) => row?.product_id),
      },
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    setTableData(
      _res?.data?.rows?.map((promotion) => ({
        ...promotion,
        product: products?.data?.rows?.find(
          (product) => product.id === promotion.product_id
        ),
      }))
    );

    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const columns = [
    {
      dataIndex: "image_main",
      key: "image_main",
      align: "center",
      hidden: true,
      width: 150,
      className: "align-top",
      render: (_, data) => (
        <img
          src={
            data?.product?.image_main
              ? `${Imgproxy.getURL(data.product.image_main, {
                  width: 180,
                  height: 180,
                  resize: "fit",
                  background: "fff",
                })}`
              : "/img/no-image.svg"
          }
          className="block w-full"
          style={{ maxWidth: 150 }}
          alt="Изображение объявления"
        />
      ),
    },
    {
      title: "Объявление",
      dataIndex: "product",
      key: "product",
      align: "left",
      render: (data, all) => (
        <div>
          <div className="flex justify-start items-center gap-2">
            <span className="block text-sm text-primary">
              {Products.PRODUCT_TYPE_TITLES[data?.type]}
            </span>
            <span className="block text-sm text-neutral-400">
              {Products.PRODUCT_STATUS_TITLES[data?.status]}
            </span>
          </div>
          <Link
            to={`/listings/view/${data.type}/${data.id}`}
            className="block m-0 text-base text-black"
          >
            {data.title}
          </Link>
          <span className="block mb-2 font-normal text-black text-sm">
            <Link to={`/users/view/${all?.user?.id}`}>
              {all?.user?.display_name}
            </Link>
          </span>
        </div>
      ),
    },
    {
      title: "Тип продвижения",
      dataIndex: "source",
      key: "source",
      align: "center",
      render: (data) => {
        const source = find(propEq("value", data), services);

        return (
          <div className="flex flex-col justify-center">
            <img
              src={source?.icon}
              alt="Иконка продвижения"
              className="block h-5 mb-2"
            />
            <span className="block text-sm font-medium text-black">
              {source.label}
            </span>
          </div>
        );
      },
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, data) => (
        <Button onClick={() => setPromotionId(data.id)} icon={<EyeOutlined />}>
          Открыть
        </Button>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-6">
      {includes("services", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <ServicesFilter onChange={onFilterChange} />
        </div>
      )}
      {includes("products", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <ProductsFilter onChange={onFilterChange} />
        </div>
      )}
      {includes("users", showFilters) && (
        <div className="col-span-12 lg:col-span-4">
          <UsersFilter onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={
            compact
              ? columns.filter(
                  (col) => col.key !== "image_main" && col.key !== "actions",
                  columns
                )
              : columns
          }
        />
      </div>
      <Modal
        okButtonProps={{ hidden: true }}
        cancelText="Закрыть"
        width={970}
        onCancel={() => setPromotionId(null)}
        open={!!promotionId}
        title="Внешнее продвижение"
      >
        {promotionId && <View id={promotionId} />}
      </Modal>
    </div>
  );
}
