import { useSearchParams } from "react-router-dom";
import { MailTwoTone, ReloadOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "@contexts";
import config from "@config";
import { differenceInMinutes } from "date-fns";
import { Button, Divider } from "antd";
import { Loading } from "@components";
import { useEffect, useState } from "react";
import { startsWith } from "ramda";
import { Auth } from "@api";

export default function ConfirmPage() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requestedAt, setRequestedAt] = useState(new Date());
  const [requestDisabled, setRequestDisabled] = useState(true);
  const { currentUser, loading: sending, sendVerification, logout } = useAuth();
  const [params] = useSearchParams();
  const redirectURL = params.get("redirect_url") || null;

  useEffect(() => {
    return onRequestEmail();
    /* eslint-disable-next-line */
  }, []);

  useEffect(() => {
    const onFocus = async () => {
      const _res = await Auth.getAuth();

      if (_res?.error) {
        return;
      }

      if (redirectURL && !startsWith("/", redirectURL)) {
        return window.location.replace(redirectURL);
      }

      return window.location.replace(redirectURL || "/");
    };

    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  }, [redirectURL]);

  useEffect(() => {
    const intv = setInterval(() => {
      if (differenceInMinutes(new Date(), new Date(requestedAt)) >= 1) {
        setRequestDisabled(false);
        return clearInterval(intv);
      }
    }, 1000);

    return () => clearInterval(intv);
  }, [requestedAt]);

  const onRequestEmail = async () => {
    if (currentUser) {
      return;
    }

    setLoading(true);
    const _user = await sendVerification();

    if (!_user) {
      await onLogout();
    }

    setRequestedAt(new Date());
    setUser(_user);
    setLoading(false);
  };

  const onLogout = async () => {
    setLoading(true);
    await logout();
    setTimeout(() => {
      return document.location.replace("/login");
    }, 2000);
  };

  if (loading || sending || !user?.email) {
    return <Loading />;
  }

  return (
    <div className="login-page">
      <div className="header mb-20">
        <div className="container content-max">
          <a
            href={config.frontend}
            title="Sdelka.kz — Поиск готового бизнеса, франшиз и инвестиции"
            className="logo-top"
          >
            <img
              src="/img/logo.svg"
              alt="Sdelka.kz — Поиск готового бизнеса, франшиз и инвестиции"
              title="Sdelka.kz — Поиск готового бизнеса, франшиз и инвестиции"
              width={242}
              height={25}
            />
          </a>
        </div>
      </div>
      <div className="container content-max after-header">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-6 lg:col-start-4">
            <div className="block w-full border border-solid border-neutral-300 p-5 rounded-md">
              <div className="flex justify-start items-start">
                <MailTwoTone twoToneColor="#02b35d" className="text-3xl" />
                <div className="ml-4">
                  <h1 className="leading-none text-base">
                    Письмо с подтверждением отправлено
                  </h1>
                  <span className="block text-sm text-black">
                    Пожалуйста, <strong>пройдите по ссылке</strong> в письме,
                    которое мы отправили вам на адрес{" "}
                    <a href={`mailto:${user.email}`}>{user.email}</a>.
                  </span>
                  <span className="block text-sm font-medium mt-5 text-black">
                    Не пришло письмо?
                  </span>
                  <span className="block text-sm text-neutral-500 mt-2">
                    Подождите пару минут и проверьте снова. Проверьте папку со
                    «Спамом», возможно письмо ушло туда.
                  </span>
                  <span className="block text-sm font-medium mt-5 text-black">
                    Не помогло?
                  </span>
                  <div className="flex justify-start items-center flex-wrap">
                    <Button
                      icon={<ReloadOutlined />}
                      type="link"
                      className="mx-0 px-0"
                      onClick={onRequestEmail}
                      disabled={requestDisabled}
                    >
                      Запросить еще раз
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      href="mailto:info@sdelka.kz"
                      type="link"
                      className="mx-0 px-0"
                    >
                      Обратиться в поддержку
                    </Button>
                  </div>
                  <Divider className="my-4" />
                  <Button
                    icon={<LogoutOutlined />}
                    type="link"
                    className="mx-0 px-0"
                    onClick={onLogout}
                    danger
                  >
                    Выйти из аккаунта
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
