import * as Pages from "@pages/moderator";
import * as Loaders from "./loaders";
import Layout from "@modules/layouts/moderator";

const routes = [
  {
    path: "/reports",
    element: (
      <Layout page="reports">
        <Pages.Reports.List />
      </Layout>
    ),
    loader: Loaders.Companies.getCompany,
  },
  {
    path: "/companies/view/:id",
    element: (
      <Layout page="companies">
        <Pages.Companies.View />
      </Layout>
    ),
    loader: Loaders.Companies.getCompany,
  },
  {
    path: "/companies",
    element: (
      <Layout page="companies">
        <Pages.Companies.List />
      </Layout>
    ),
  },
  {
    path: "/payments",
    element: (
      <Layout page="payments">
        <Pages.Payments.List />
      </Layout>
    ),
  },
  {
    path: "/dict/:type/edit/:id",
    element: (
      <Layout page="dict">
        <Pages.Dict.Edit />
      </Layout>
    ),
    loader: Loaders.Dict.getData,
  },
  {
    path: "/dict/:type/new",
    element: (
      <Layout page="dict">
        <Pages.Dict.New />
      </Layout>
    ),
    loader: Loaders.Dict.getData,
  },
  {
    path: "/dict/:type",
    element: (
      <Layout page="dict">
        <Pages.Dict.List />
      </Layout>
    ),
    loader: Loaders.Dict.getData,
  },
  {
    path: "/users/view/:id",
    element: (
      <Layout page="users">
        <Pages.Users.View />
      </Layout>
    ),
  },
  {
    path: "/users",
    element: (
      <Layout page="users">
        <Pages.Users.List />
      </Layout>
    ),
  },
  {
    path: "/promotions/external",
    element: (
      <Layout page="external-promotions">
        <Pages.ExternalPromotions.List />
      </Layout>
    ),
  },
  {
    path: "/promotions",
    element: (
      <Layout page="promotions">
        <Pages.Promotions.List />
      </Layout>
    ),
  },
  {
    path: "/listings/edit/:type/:id",
    element: (
      <Layout page="listings">
        <Pages.Listings.Edit />
      </Layout>
    ),
    loader: Loaders.Listings.getListing,
  },
  {
    path: "/listings/view/:type/:id",
    element: (
      <Layout page="listings">
        <Pages.Listings.View />
      </Layout>
    ),
    loader: Loaders.Listings.getListing,
  },
  {
    path: "/listings",
    element: (
      <Layout page="listings">
        <Pages.Listings.List />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout page="dashboard">
        <Pages.Dashboard />
      </Layout>
    ),
  },
];

export default routes;
