import { useEffect, useState } from "react";
import { Loading } from "@components";
import { Listings } from "@modules/customer";
import { Link } from "react-router-dom";
import { message } from "antd";
import {
  HeartOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import Empty from "./empty";
import { useTranslation } from "react-i18next";
import * as API from "@api/customer";

export default function AllListings({ className, isApp = false }) {
  const [loading, setLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getListings();
  }, []);

  const getListings = async () => {
    setLoading(true);
    const [sales, franchises, investments] = await Promise.all([
      API.Sales.getAll({ limit: 50 }),
      API.Franchises.getAll({ limit: 50 }),
      API.Investments.getAll({ limit: 50 }),
    ]);

    if (
      !sales ||
      sales.error ||
      !franchises ||
      franchises.error ||
      !investments ||
      investments.error
    ) {
      return message.error(
        "Невозможно загрузить данные, попробуйте снова позже",
        3
      );
    }

    setListings([
      ...sales?.data?.rows,
      ...franchises?.data?.rows,
      ...investments?.data?.rows,
    ]);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={classNames(
        "grid grid-cols-12 gap-8 my-10 listings-page",
        className
      )}
    >
      <div hidden={listings.length > 0} className="col-span-12">
        <Empty isApp={isApp} />
      </div>
      {listings.map((listing, i) => (
        <div key={i} className="col-span-12">
          <Listings.Item isApp={isApp} data={listing} />
        </div>
      ))}
      <div
        className={classNames("col-span-12 pt-10 grid grid-cols-3 gap-4", {
          hidden: isApp,
        })}
      >
        <div className="col-span-3 lg:col-span-1">
          <Link className="cta-preview" to="/favorites">
            <div className="block">
              <i className="not-italic w-full font-medium block m-0 text-base text leading-tight">
                {t("nav.favorites")}
              </i>
              <i className="not-italic w-full block text-sm text-neutral-500">
                {t("nav.likedListings")}
              </i>
            </div>
            <HeartOutlined className="text-green-600 text-2xl m-0 p-0 ml-2 flex items-center" />
          </Link>
        </div>
        <div className="col-span-3 lg:col-span-1">
          <Link className="cta-preview" to="/notifications">
            <div className="block">
              <i className="not-italic w-full font-medium block m-0 text-base text leading-tight">
                {t("nav.notifications")}
              </i>
              <i className="not-italic w-full block text-sm text-neutral-500">
                {t("nav.moderatorMessages")}
              </i>
            </div>
            <MessageOutlined className="text-green-600 text-2xl m-0 p-0 ml-2 flex items-center" />
          </Link>
        </div>
        <div className="col-span-3 lg:col-span-1">
          <Link className="cta-preview" to="/settings">
            <div className="block">
              <i className="not-italic w-full font-medium block m-0 text-base text leading-tight">
                {t("nav.settings")}
              </i>
              <i className="not-italic w-full block text-sm text-neutral-500">
                {t("nav.cabinetAndDetails")}
              </i>
            </div>
            <SettingOutlined className="text-green-600 text-2xl m-0 p-0 ml-2 flex items-center" />
          </Link>
        </div>
      </div>
    </div>
  );
}
