import { Select } from "antd";

export default function Status(params) {
  return (
    <Select
      className="w-full"
      placeholder="Статус"
      allowClear
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(v) => params.onChange("is_active", v)}
    >
      <Select.Option value="1">На сайте</Select.Option>
      <Select.Option value="0">Снятые с сайта</Select.Option>
    </Select>
  );
}
