import { Card, Steps, Empty } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import moment from "moment";

import { PRODUCT_STATUS_TITLES } from "@utils/products";

export default function History({ data, className }) {
  return (
    <Card bordered={false} title="История изменений" className={className}>
      {data?.history?.length === 0 && <Empty />}
      <Steps
        direction="vertical"
        status="process"
        items={data?.history?.map((history) => ({
          title: PRODUCT_STATUS_TITLES[history?.status],
          description: moment(history?.created_at).format(
            "DD MMM YYYY · HH:mm"
          ),
          icon: <CaretRightOutlined />,
        }))}
      />
    </Card>
  );
}
