import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message, Avatar, Button, Divider, Tag, Popconfirm } from "antd";
import { services } from "@modules/moderator/promotions/list/filters/services";
import { find, propEq } from "ramda";
import { Link } from "react-router-dom";
import {Imgproxy} from '@utils';
import * as API from "@api/moderator";
import moment from "moment";

export default function Payment({ transactionId = null }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (transactionId) {
      fetchData();
    }
    /* eslint-disable-next-line */
  }, [transactionId]);

  const fetchData = () => {
    setLoading(true);

    API.Payments.getAll({ filter: { transaction_id: transactionId } }).then(
      (res) => {
        if (!res?.data) {
          setLoading(false);
          return message.error("Ошибка, попробуйте еще раз", 2);
        }

        setData(res?.data?.rows);
        setLoading(false);
      }
    );
  };

  if (loading) {
    return <Loading compact />;
  }

  const onPaymentRevoke = () => {
    setLoading(true);

    API.Payments.revoke({ transaction_id: transactionId })
      .then((res) => {
        if (res?.error) {
          setLoading(false);
          return message.error("Ошибка, попробуйте еще раз", 2);
        }

        return message.success("Платеж успешно возвращен.", 2, () => {
          setLoading(true);
          fetchData();
        });
      })
      .catch(() => {
        setLoading(false);
        return message.error("Ошибка, попробуйте еще раз", 2);
      });
  };

  const getPayments = () => {
    return data?.map((payment, i) => (
      <div key={i} className="col-span-12">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-4">
            <span className="block text-base font-semibold text-slate-700 mb-2">
              Оплата за услугу
            </span>
            <span className="flex justify-start items-center flex-wrap text-base font-normal text-slate-800">
              <img
                src={
                  find(propEq("value", payment?.tariff?.service_code), services)
                    ?.icon
                }
                alt="Иконка продвижения"
                className="block h-5 mr-2"
              />{" "}
              {payment?.tariff?.name} ({payment?.tariff?.quantity}{" "}
              {payment?.tariff?.unit === "month" ? "мес." : "дн."})
            </span>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <span className="block text-base font-semibold text-slate-700 mb-2">
              Куплен с пакетом
            </span>
            <span className="block text-base font-normal text-slate-800">
              {payment?.package ? payment?.package?.name : "Нет"}
            </span>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <span className="block text-base font-semibold text-slate-700 mb-2">
              Стоимость услуги
            </span>
            <span className="block text-base font-normal text-slate-800">
              {new Intl.NumberFormat("ru-KZ", {
                style: "currency",
                currency: "KZT",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(payment?.tariff_price?.price)}
            </span>
          </div>
          <div className="col-span-12">
            <Divider className="my-2" />
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Оплата за услугу
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.pg_description || data[0]?.tariff?.name}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Подвязанное объявление
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.product && (
            <Link
              to={`/listings/view/${data[0]?.product?.type}/${data[0]?.product?.id}`}
            >
              {data[0]?.product?.title}
            </Link>
          )}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Покупатель
        </span>
        <span className="flex flex-wrap justify-start items-center">
          <Avatar
            src={Imgproxy.getURL(data[0]?.user?.avatar, {width: 100, height: 100})}
            className="mr-2"
          />
          <div>
            <span className="block text-base font-medium text-slate-800 leading-tight">
              {data[0]?.user?.display_name}
            </span>
            <Link
              target="_blank"
              to={`/users/view/${data[0]?.user?.id}`}
              className="block text-sm leading-none"
            >
              {data[0]?.user?.email}
            </Link>
          </div>
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Сумма
        </span>
        <span className="block text-base font-normal text-slate-800">
          {new Intl.NumberFormat("ru-KZ", {
            style: "currency",
            currency: "KZT",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(data[0]?.pg_ps_full_amount || data[0]?.amount)}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Вид оплаты
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.payment_source === "paybox"
            ? "Онлайн (Paybox)"
            : "Другой (безнал, касса)"}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Дата оплаты
        </span>
        <span className="block text-base font-normal text-slate-800">
          {moment(data[0]?.charge_date || data[0]?.created_at)?.format("HH:mm DD.MM.YYYY")}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Обработан
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.is_verified ? "Да" : "Нет"}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Отклонен
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.is_declined ? "Да" : "Нет"}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Отменен
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.is_cancelled ? "Да" : "Нет"}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Оплата принята (Paybox)
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.is_charged ? "Да" : "Нет"}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Оплата возвращена
        </span>
        <span className="block text-base font-normal text-slate-800">
          {data[0]?.is_returned ? "Да" : "Нет"}
        </span>
        <Popconfirm
          title="Оформить полный возврат?"
          description={
            <span className="block text-sm max-w-xs">
              Все связанные услуги будут деактивированы, а сумма (
              {data[0]?.pg_ps_full_amount}) вернется к пользователю в теч. 5-10
              дней.
            </span>
          }
          okText="Оформить возврат"
          cancelText="Отмена"
          onConfirm={onPaymentRevoke}
        >
          <Button
            hidden={data[0]?.is_returned}
            className="mt-2"
            type="primary"
            danger
            size="small"
          >
            Оформить возврат
          </Button>
        </Popconfirm>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          ID транзакции
        </span>
        <span className="block text-base font-normal text-slate-800">
          <Tag>{data[0]?.transaction_id}</Tag>
        </span>
      </div>
      <div className="col-span-12">
        <Divider>Детализация услуг</Divider>
      </div>
      {getPayments()}
    </div>
  );
}
