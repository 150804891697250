import classNames from "classnames";
import { Card, Button, Badge, Dropdown, Descriptions, Modal } from "antd";
import {
  EditOutlined,
  SettingTwoTone,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getURL } from "@utils/imgproxy";
import { splitAt } from "ramda";

export default function CompanyItem({ className, data, onCompanyDestroy }) {
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "edit":
        return navigate(`/companies/edit/${data?.company?.id}`);

      case "view":
        return Modal.info({
          icon: <SettingTwoTone />,
          okText: "Понятно",
          title: "Просмотр профиля компании",
          content: (
            <span className="text-base font-normal">
              Уважаемый Партнер,
              <br />
              <br />
              На данный момент раздел услуг на сайте находится в разработке и
              скоро будет доступен всем пользователям.
              <br />
              <br />
              Благодарим за Ваш интерес и будем рады опубликовать вашу компанию
              на сайте в скором времени.
            </span>
          ),
        });
      // return (window.location.href = `${config.frontend}/services/s/${data?.company?.id}/${data?.company?.slug}`);
      case "delete":
        return Modal.confirm({
          okText: "Удалить безвозвратно",
          okButtonProps: { danger: true },
          cancelText: "Отменить",
          title: "Удалить компанию?",
          width: 500,
          maskClosable: true,
          content: (
            <span className="text-base">
              Вы действительно хотите удалить компанию{" "}
              <strong>{data?.company?.title}</strong>? Профиль компании будет
              снят с сайта и удален безвозвратно.
            </span>
          ),
          onOk: () => onCompanyDestroy({ id: data?.company?.id }),
        });

      default:
        break;
    }
  };

  const options = {
    onClick: handleMenuClick,
    items: [
      {
        label: "Редактировать",
        key: "edit",
        icon: <EditOutlined />,
      },
      {
        label: "Посмотреть на сайте",
        key: "view",
        hidden: !data?.company?.is_active,
        icon: <EyeOutlined />,
      },
      {
        label: "Удалить компанию",
        key: "delete",
        danger: true,
        hidden: !data?.company?.is_active,
        icon: <DeleteOutlined />,
      },
    ],
  };

  return (
    <Card
      className={classNames("w-full my-6 md:my-10", className)}
      bodyStyle={{ marginTop: -40 }}
    >
      <div className="flex justify-start">
        <img
          className="w-20 h-20 rounded-md block mr-3 bg-white shadow-md"
          src={getURL(data?.company?.logo, { width: 100, height: 100 })}
          alt={data?.company?.title}
        />
        <div className="flex w-full justify-between items-start mt-7">
          <div>
            <span className="text-base font-medium block w-full text-black mb-1 leading-tight">
              {data?.company?.title}
            </span>
            <span className="text-sm block w-full text-neutral-400 leading-tight">
              {data?.company?.is_active ? (
                <Badge status="success" text="На сайте" />
              ) : (
                <Badge status="warning" text="Убрано с сайта" />
              )}{" "}
              | {data?.category?.name}
            </span>
          </div>
          <Dropdown.Button
            className="hidden md:flex w-fit"
            menu={options}
            onClick={() => handleMenuClick({ key: "edit" })}
          >
            Редактировать
          </Dropdown.Button>
        </div>
      </div>
      <Button
        className="md:hidden mt-5"
        block
        type="primary"
        onClick={() => handleMenuClick({ key: "edit" })}
      >
        Редактировать
      </Button>
      <Button
        className="md:hidden mt-2"
        block
        type="link"
        onClick={() => handleMenuClick({ key: "view" })}
      >
        Посмотреть на сайте
      </Button>
      <Descriptions
        layout="vertical"
        colon={false}
        size="small"
        column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 2 }}
        className="w-full mt-5"
      >
        <Descriptions.Item label="Страны присутствия">
          {data?.countries?.length > 2
            ? `${splitAt(2, data.countries)[0]
                .map((d) => d.name)
                .join(",")}, +${data.countries.length - 2}`
            : data.countries.map((d) => d.name).join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Города присутствия">
          {data?.cities?.length > 2
            ? `${splitAt(2, data.cities)[0]
                .map((d) => d.name)
                .join(", ")}, +${data.cities.length - 2}`
            : data.cities.map((d) => d.name).join(", ")}
        </Descriptions.Item>
        <Descriptions.Item label="Добавленные услуги">
          {data?.company?.services?.length}
        </Descriptions.Item>
        <Descriptions.Item label="Добавленные фотографии">
          {data?.company?.image_list?.length}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
}
