import { Table } from "antd";
import { Link } from "react-router-dom";
import { find, propEq } from "ramda";
import {Imgproxy} from '@utils';
import classNames from "classnames";
import moment from "moment";

export default function DataTable({
  className = null,
  data,
  filters,
  showHeader,
  getTableData,
  pagination,
  loading,
}) {
  return (
    <Table
      showHeader={showHeader}
      onChange={getTableData}
      pagination={pagination}
      dataSource={data}
      loading={loading}
      className={classNames("rounded-sm overflow-hidden", className)}
      rowKey="id"
      scroll={{ x: "max-content" }}
      columns={[
        {
          dataIndex: "image_main",
          key: "image_main",
          align: "center",
          width: 150,
          className: "align-top",
          render: (_, data) => (
            <img
              className="block w-full h-full max-h-20 rounded-sm shadow-md object-cover object-center"
              src={Imgproxy.getURL(data.product.image_main, { width: 354, height: 240, resize: "fit", background: "fff" })}
              alt="Ваше объявление"
            />
          ),
        },
        {
          title: "Объявление",
          dataIndex: "product",
          key: "product",
          align: "left",
          render: (_, { product }) => (
            <div>
              <Link
                target="_blank"
                to={`/listings/view/${product.type}/${product.id}`}
                className="block text-base"
              >
                {product.title}
              </Link>
              <span className="block text-neutral-500 text-sm">
                {product.type === "sale" && "Бизнес"}
                {product.type === "franchise" && "Франшиза"}
                {product.type === "investment" && "Инвестиции"}
              </span>
              <span className="block font-normal text-green-500 text-sm">
                {new Intl.NumberFormat("ru-KZ", {
                  style: "currency",
                  currency: "kzt",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(product.price)}
              </span>
            </div>
          ),
        },
        {
          title: "Причина",
          dataIndex: "outcome_reason",
          key: "outcome_reason",
          align: "center",
          render: (outcome_reason) => (
            <span className="text-base font-medium text-black block text-center">
              {find(propEq("id", outcome_reason), filters)?.name}
            </span>
          ),
        },
        {
          title: "Комментарии",
          dataIndex: "outcome_feedback",
          key: "outcome_feedback",
          align: "center",
          render: (outcome_feedback) => (
            <span className="text-sm text-neutral-600 block">
              {outcome_feedback}
            </span>
          ),
        },
        {
          title: "Дата снятия",
          dataIndex: "created_at",
          key: "created_at",
          align: "center",
          render: (created_at) => (
            <span className="text-sm text-neutral-600 block">
              {moment(created_at).format("HH:mm DD.MM.YYYY")}
            </span>
          ),
        },
      ]}
    />
  );
}
