import { useEffect, useState } from "react";
import { Loading } from "@components";
import { message, Result, Button } from "antd";
import { Link } from "react-router-dom";
import { GlobalOutlined, PlusOutlined } from "@ant-design/icons";
import * as API from "@api/partner";
import CompanyItem from "./item";

export default function Companies({ initialData = null, className }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData || []);

  const onCompanyDestroy = (data) => {
    setLoading(true);

    API.Companies.destroy(data)
      .then((res) => {
        setLoading(false);
        if (!res || res?.error) {
          throw Error("Ошибка удаления компании, попробуйте еще раз", 3);
        }

        return message.success("Профиль компании успешно удален", 2, getData);
      })
      .catch((err) =>
        message.error(err?.message || "Ошибка соединения", 3, () =>
          setLoading(false)
        )
      );
  };

  useEffect(() => {
    if (!initialData) {
      setLoading(true);
      getData();
    }
  }, [initialData]);

  const getData = () =>
    API.Companies.getAll({ limit: 100 })
      .then((res) => {
        if (!res || res?.error) {
          throw Error(
            "Ошибка получения списка компаний, проверьте соединение с интернетом и попробуйте еще раз",
            3
          );
        }

        setData(res?.data?.rows || []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err || "Ошибка соединения", 3);
      });

  if (loading) {
    return <Loading compact />;
  }

  if (data?.length === 0) {
    return (
      <Result
        icon={<GlobalOutlined className="text-green-600" />}
        title="Добавьте компанию на Sdelka.kz"
        subTitle="Получайте заявки от клиентов и увеличивайте продажи"
        extra={
          <Link to="/companies/new">
            <Button icon={<PlusOutlined />} type="primary">
              Добавить компанию
            </Button>
          </Link>
        }
        className="w-full bg-neutral-50 rounded-lg my-10"
      />
    );
  }

  return (
    <div className={className}>
      {data?.map((item, i) => (
        <CompanyItem onCompanyDestroy={onCompanyDestroy} key={i} data={item} />
      ))}
    </div>
  );
}
