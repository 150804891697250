import { useState, useEffect } from "react";
import { Loading } from "@components";
import { message, Button, Popconfirm } from "antd";
import { services } from "../list/filters/services";
import { find, propEq } from "ramda";
import { Link } from "react-router-dom";
import { Products } from "@utils";
import * as API from "@api/moderator";
import moment from "moment";

export default function Promotion({ initialData = null, id = null }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (!data) {
      fetchData();
    }
    /* eslint-disable-next-line */
  }, []);

  const fetchData = () => {
    setLoading(true);

    API.ExternalPromotions.getOne({ id }).then(async (res) => {
      if (!res?.data) {
        setLoading(false);
        return message.error("Ошибка, попробуйте еще раз", 2);
      }

      const product = await API.Products.getAll({
        filter: {
          id: res.data.product_id,
        },
      }).then((res) => res?.data?.rows?.[0] || null);

      setData({ ...res.data, product });
      setLoading(false);
    });
  };

  const onDelete = () => {
    return API.ExternalPromotions.destroy({ id: data?.id }).then((res) => {
      if (!res || res?.error) {
        return message.error("Невозможно удалить продвижение", 3);
      }

      return message.success("Продвижение успешно удалено", 3, () => {
        window.location.reload(false);
      });
    });
  };

  if (loading) {
    return <Loading compact />;
  }

  const service = find(propEq("value", data?.source), services);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Объявление
        </span>
        <span className="block text-base font-normal text-slate-800">
          <Link
            to={`/listings/view/${data?.product?.type}/${data?.product?.id}`}
          >
            {data?.product?.title}
          </Link>
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Тип объявления
        </span>
        <span className="block text-base font-normal text-slate-800">
          {Products.PRODUCT_TYPE_TITLES[data?.product?.type]}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Статус объявления
        </span>
        <span className="block text-base font-normal text-slate-800">
          {Products.PRODUCT_STATUS_TITLES[data?.product?.status]}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Тариф
        </span>
        <span className="block text-base font-normal text-slate-800">
          <div className="flex flex-col justify-start items-start">
            <img
              src={service?.icon}
              alt="Иконка продвижения"
              className="block h-5 mb-2"
            />
            <i className="not-italic block">{service?.label}</i>
          </div>
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Дата активации
        </span>
        <span className="block text-base font-normal text-slate-800">
          {moment(data?.created_at).format("HH:mm DD.MM.YYYY")}
        </span>
      </div>
      <div className="col-span-12 lg:col-span-4">
        <span className="block text-base font-semibold text-slate-700 mb-2">
          Удалить продвижение
        </span>
        <Popconfirm
          title="Удалить?"
          description="Это продвижение будет прекращено безвозвратно."
          okText="Удалить"
          cancelText="Отмена"
          onConfirm={onDelete}
        >
          <Button type="primary" danger size="small">
            Удалить продвижение
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}
