import { Card, Divider, Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { types } from "../head/dict";
import { find, propEq } from "ramda";

export default function Info({ data }) {
  const type = find(propEq("value", data?.product?.type))(types);
  const price = data?.product?.price_max
    ? `${Intl.NumberFormat("ru-KZ", {
        currency: "kzt",
        style: "currency",
        maximumFractionDigits: 0,
      }).format(data?.product?.price)} - ${Intl.NumberFormat("ru-KZ", {
        currency: "kzt",
        style: "currency",
        maximumFractionDigits: 0,
      }).format(data?.product?.price_max)}`
    : Intl.NumberFormat("ru-KZ", {
        currency: "kzt",
        style: "currency",
        maximumFractionDigits: 0,
      }).format(data?.product?.price);

  return (
    <Card bordered={false} title="Информация о бизнесе">
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Название
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.product?.title}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Категория
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.category?.name}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Тип объявления
          </span>
          <span className="block text-base font-normal text-slate-800">
            {type.label}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Запрашиваемая сумма
          </span>
          <span className="block text-base font-normal text-primary">
            {price}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Страна
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.country?.name}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-4">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Город
          </span>
          <span className="block text-base font-normal text-slate-800">
            {data?.city?.name}
          </span>
        </div>
        <div className="col-span-12">
          <Divider className="m-0" />
        </div>
        <div className="col-span-12">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Описание
          </span>
          <p className="text-base leading-relaxed text-slate-800 m-0 whitespace-pre-wrap">
            {data[data?.product?.type].description}
          </p>
        </div>
        <div className="col-span-12">
          <Divider className="m-0" />
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Веб-сайт
          </span>
          <span className="block text-base font-normal">
            {data?.product?.website && (
              <Button
                className="m-0 p-0 h-fit text-base"
                type="link"
                icon={<LinkOutlined />}
                target="_blank"
                href={data.product.website}
              >
                Открыть в новом окне
              </Button>
            )}
          </span>
        </div>
        <div className="col-span-12 md:col-span-6">
          <span className="block text-base font-semibold text-slate-700 mb-2">
            Ссылка на YouTube
          </span>
          <span className="block text-base font-normal">
            {data?.product?.meta?.youtube_url && (
              <Button
                className="m-0 p-0 h-fit text-base"
                type="link"
                icon={<LinkOutlined />}
                target="_blank"
                href={data.product.meta.youtube_url}
              >
                Открыть в новом окне
              </Button>
            )}
          </span>
        </div>
      </div>
    </Card>
  );
}
