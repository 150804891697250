import { useEffect, useState } from "react";
import { Table, Button, message, Modal, Tag } from "antd";
import { EyeOutlined, UserOutlined, LinkOutlined } from "@ant-design/icons";
import { pathOr, includes } from "ramda";
import ProductsFilter from "./filters/products";
import UsersFilter from "./filters/users";
import ReasonProductFilter from "./filters/reason_product";
import DatesFilter from "./filters/dates";
import View from "../view";
import { Reports, Products } from "@utils";
import * as API from "@api/moderator";
import moment from "moment";
import "moment/locale/ru";

moment.locale("ru");

export default function List({
  showHeader = true,
  showFilters = ["product", "reason", "users", "dates"],
  defaultFilterValues = null,
  compact = false,
  pageSize = 10,
  showPagination = true,
}) {
  const [loading, setLoading] = useState(true);
  const [reportId, setReportId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    type: pathOr("product", ["type"], defaultFilterValues),
    product_id: pathOr(undefined, ["product_id"], defaultFilterValues),
    company_id: pathOr(undefined, ["company_id"], defaultFilterValues),
    user_id: pathOr(undefined, ["user_id"], defaultFilterValues),
    created_at: pathOr(undefined, ["created_at"], defaultFilterValues),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize,
    total: 1,
    position: ["bottomright"],
    showSizeChanger: true,
    showTotal: (total) => `Найдено: ${total}`,
  });

  useEffect(() => {
    getTableData();
    /* eslint-disable-next-line */
  }, [filter]);

  async function getTableData(params = pagination) {
    setLoading(true);
    const _res = await API.Reports.getAll({
      filter,
      limit: params.pageSize,
      offset: params.current * params.pageSize - params.pageSize,
    });

    if (!_res.data) {
      return message.error("Ошибка, попробуйте еще раз");
    }

    setTableData(_res.data.rows);
    setPagination({
      ...params,
      total: _res.data.count,
      showTotal: (total) => `Найдено: ${total}`,
    });
    setLoading(false);
  }

  function onFilterChange(f, v) {
    setFilter(
      { ...filter, [f]: v || undefined },
      setPagination({ ...pagination, current: 1 })
    );
  }

  const columns = [
    filter?.type === "product"
      ? {
          title: "Объявление",
          dataIndex: "product",
          key: "product",
          align: "left",
          render: (data) => {
            return (
              <div>
                <span className="block font-normal text-sm text-neutral-400">
                  {data.type === "sale"
                    ? "Бизнес"
                    : data.type === "franchise"
                    ? "Франшиза"
                    : "Инвестиции"}
                </span>

                <span className="block font-semibold w-full max-w-xs">
                  {data?.title}
                </span>
                <div className="flex justify-start items-center gap-2 flex-wrap">
                  <Button
                    href={`/listings/view/${data?.type}/${data?.id}`}
                    target="_blank"
                    type="link"
                    className="w-fit h-fit m-0 p-0"
                    icon={<LinkOutlined />}
                  >
                    {Products.PRODUCT_STATUS_TITLES[data?.status]}
                  </Button>
                  <Button
                    type="link"
                    className="w-fit h-fit m-0 p-0"
                    icon={<UserOutlined />}
                    href={`/users/view/${data?.user_id}`}
                    target="_blank"
                  >
                    {data?.display_name || "Пользователь"}
                  </Button>
                </div>
              </div>
            );
          },
        }
      : {
          title: "Компания",
          dataIndex: "company",
          key: "company",
          align: "left",
          render: (data) => {
            return (
              <div>
                <span className="block font-normal text-sm text-neutral-400">
                  Компания (Услуги)
                </span>
                <span className="block font-semibold w-full max-w-xs">
                  {data?.title}
                </span>
                <div className="flex justify-start items-center gap-2 flex-wrap">
                  <Button
                    href={`/companies/view/${data?.id}`}
                    target="_blank"
                    type="link"
                    className="w-fit h-fit m-0 p-0"
                    icon={<LinkOutlined />}
                  >
                    {data?.is_active ? "На сайте" : "Неактивна"}
                  </Button>
                </div>
              </div>
            );
          },
        },
    {
      title: "Причина",
      dataIndex: "report",
      key: "report",
      align: "center",
      render: (data) => (
        <Tag className="m-0" color="orange">
          {
            Reports.REASON_TITLES[
              filter.type === "product"
                ? data.product_reason
                : data.company_reason
            ]
          }
        </Tag>
      ),
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      render: (_, data) => (
        <div className="flex flex-col">
          <span className="m-0 p-0 text-sm font-medium text-black">
            {moment(data?.report?.created_at).fromNow()}
          </span>
          <span className="m-0 p-0 text-sm text-neutral-500">
            {moment(data?.report?.created_at).format("HH:mm DD.MM.YYYY")}
          </span>
        </div>
      ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      align: "right",
      render: (_, data) => (
        <Button
          onClick={() => setReportId(data?.report?.id)}
          icon={<EyeOutlined />}
        >
          Открыть
        </Button>
      ),
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-6">
      {filter.type === "product" && includes("reason", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <ReasonProductFilter onChange={onFilterChange} />
        </div>
      )}
      {filter.type === "company" && includes("reason", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <ReasonProductFilter onChange={onFilterChange} />
        </div>
      )}
      {includes("product", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <ProductsFilter onChange={onFilterChange} />
        </div>
      )}
      {includes("users", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <UsersFilter onChange={onFilterChange} />
        </div>
      )}
      {includes("dates", showFilters) && (
        <div className="col-span-12 lg:col-span-3">
          <DatesFilter onChange={onFilterChange} />
        </div>
      )}
      <div className="col-span-12">
        <Table
          showHeader={showHeader}
          onChange={getTableData}
          pagination={showPagination && pagination}
          dataSource={tableData}
          loading={loading}
          className="rounded-sm overflow-hidden"
          rowKey="id"
          scroll={{ x: "max-content" }}
          columns={
            compact
              ? columns.filter(
                  (col) => col.key !== "product" && col.key !== "company",
                  columns
                )
              : columns
          }
        />
      </div>
      <Modal
        key={reportId}
        okButtonProps={{ hidden: true }}
        cancelText="Закрыть"
        width={970}
        onCancel={() => setReportId(null)}
        open={!!reportId}
        title="Информация о жалобе"
      >
        {reportId && <View id={reportId} />}
      </Modal>
    </div>
  );
}
