import { useEffect, useState } from "react";
import { Form, Checkbox } from "antd";
import { useLocale } from "@contexts/locale";
import * as CoreAPI from "@api";

export default function ProductOptions({ form }) {
  const { locale } = useLocale();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (form.getFieldValue("type")) {
      getOptions();
    }

    async function getOptions() {
      const _res = await CoreAPI.ProductOptions.getAll({
        limit: 100,
        filter: { product_type: form.getFieldValue("type") },
        attributes: "id,name,name_kk,product_type",
      });

      setOptions(_res?.data?.rows || []);
    }
    /* eslint-disable-next-line */
  }, [form.getFieldValue("type")]);

  if (!options || options.length === 0) {
    return null;
  }

  console.log("test...", options);

  return (
    <Form.Item name="options" className="!my-7">
      <Checkbox.Group>
        <div className="grid grid-cols-12 gap-4 gap-y-2">
          {options.map((o, i) => (
            <div className="col-span-12 sm:col-span-6 md:col-span-4" key={i}>
              <Checkbox
                value={JSON.stringify({
                  id: o.id,
                  name: o.name,
                  name_kk: o.name_kk,
                })}
              >
                {locale === "kk" ? o?.name_kk : o.name}
              </Checkbox>
            </div>
          ))}
        </div>
      </Checkbox.Group>
    </Form.Item>
  );
}
