import { useCallback, useEffect, useState } from "react";
import {
  Input,
  Button,
  Form,
  Card,
  message,
  Divider,
  Tag,
  Tooltip,
  Modal,
} from "antd";
import { useAuth } from "@contexts";
import moment from "moment";
import * as API from "@api";

export default function Details() {
  const { loading, currentUser } = useAuth();
  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState(null);
  const [isModalDeleteOpen, setModalDeleteToggle] = useState(false);

  useEffect(() => {
    (async () => {
      const _res = await API.Users.getOne();

      if (!_res?.data || _res.error) {
        return message.error(
          "Невозможно загрузить данные. Пожалуйста, попробуйте позже.",
          3
        );
      }

      setData(_res.data);
    })();
  }, [currentUser]);

  const onDetailsUpdate = async (values) => {
    setUpdating(true);
    const _res = await API.Users.update(values);
    setUpdating(false);

    if (!_res || _res.error) {
      return message.error("Произошла ошибка. Попробуйте еще раз.", 3);
    }

    return message.success("Данные успешно обновлены", 2);
  };
  const onDetailsRemoveAccount = useCallback(async () => {
    setUpdating(true);
    const _res = await API.Users.removeById(currentUser.id);
    setUpdating(false);

    if (!_res || _res.error) {
      return message.error("Произошла ошибка. Попробуйте еще раз.", 3);
    }

    message.success(
      "Ваш аккаунт удален. Сейчас вы будете перенаправлены на страницу входа.",
      2
    );
    await API.Auth.logout();
    setModalDeleteToggle(false);
    setTimeout(() => {
      window.location.href = "/login";
    }, 1500);
  }, [currentUser]);
  return (
    <Card
      title="Личные данные"
      extra={
        <Tooltip title="Вы зарегистрировались на сайте как «Партнер»">
          <Tag className="m-0" color="orange">
            Партнер
          </Tag>
        </Tooltip>
      }
      className="shadow-lg shadow-gray-100 transition-all h-full w-full"
    >
      {data && (
        <Form
          className="global-form"
          layout="vertical"
          scrollToFirstError
          onFinish={onDetailsUpdate}
          initialValues={data}
        >
          <Form.Item
            label="Имя"
            name="display_name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите имя.",
              },
            ]}
          >
            <Input placeholder="Укажите ваше имя" />
          </Form.Item>
          <Form.Item
            label="Эл. почта"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Пожалуйста, введите корректную эл. почту.",
              },
            ]}
          >
            <Input disabled placeholder="Укажите вашу почту" />
          </Form.Item>
          <span className="text-sm block text-neutral-400 my-1">
            Аккаунт создан {moment(data.created_at).format("DD.MM.YYYY")}
          </span>
          <span className="text-sm block text-neutral-400 mt-1">
            Последний вход{" "}
            {moment(data.last_login_at).format("HH:mm DD.MM.YYYY")}
          </span>
          <Divider />
          <Form.Item className="!mb-0">
            <Button
              loading={loading || updating}
              htmlType="submit"
              type="primary"
            >
              Сохранить
            </Button>
          </Form.Item>
          <Form.Item className="mt-2">
            <Button
              loading={loading || updating}
              htmlType="button"
              type="primary"
              danger
              onClick={() => setModalDeleteToggle(true)}
            >
              Удалить аккаунт
            </Button>
            <Modal
              title="Удалить аккаунт навсегда?"
              open={isModalDeleteOpen}
              okText="Удалить безвозвратно"
              cancelText="Отменить"
              onOk={() => onDetailsRemoveAccount()}
              onCancel={() => setModalDeleteToggle(false)}
              confirmLoading={loading || updating}
              okButtonProps={{
                danger: true,
              }}
            >
              <p>
                Вы собираетесь удалить Ваш аккаунт безвозвратно. Это приведет к
                удалению всех ваших данных, включая Объявления, Уведомления,
                Сохраненные и др. Это действие нельзя отменить.
              </p>
            </Modal>
          </Form.Item>
        </Form>
      )}
    </Card>
  );
}
